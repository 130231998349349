<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <el-form
        @submit.prevent="submitForm()"
        :model="formData"
        ref="formRef"
        class="w-100"
      >
        <div class="row">
          <div class="col-md-4">
            <div class="col-md-8">
              <label class="me-1">Partner Sku Code</label>
              <el-tooltip class="box-item" effect="dark" content="This field represent partner SKU code" placement="right" raw-content>
                <i class="bi bi-question-circle-fill"></i>
              </el-tooltip>
            </div>
            <div class="col-md-10 col-9">
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="index">
                    <el-input size="large" v-model="formData.partner_sku_code" type="text" placeholder="Partner Sku Code"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-md-8">
              <label class="me-1">Partner Sku Description</label>
              <el-tooltip class="box-item" effect="dark" content="This field represent partner SKU code description" placement="right" raw-content>
                <i class="bi bi-question-circle-fill"></i>
              </el-tooltip>
            </div>
            <div class="col-md-10 col-9">
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="index">
                    <el-input size="large" v-model="formData.partner_sku_description" type="text" placeholder="Partner Sku Description"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-md-8">
              <label class="me-1 required ">SKU Code</label>
              <el-tooltip class="box-item" effect="dark" content="This field represent SKU code" placement="right" raw-content>
                <i class="bi bi-question-circle-fill"></i>
              </el-tooltip>
            </div>
            <div class="col-md-12 col-9">
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="index">
                    <el-input size="large" v-model="formData.sku_code" type="text" placeholder="SKU Code"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="col-md-8">
              <label class="me-1">SKU Code Description</label>
              <el-tooltip class="box-item" effect="dark" content="This field represent SKU code description" placement="right" raw-content>
                <i class="bi bi-question-circle-fill"></i>
              </el-tooltip>
            </div>
            <div class="col-md-10 col-9">
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="sku_description">
                    <el-input size="large" v-model="formData.sku_description" type="text" placeholder="SKU Code Description"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-md-8">
              <label class="me-1">Product Name</label>
              <el-tooltip class="box-item" effect="dark" content="This field represent product name" placement="right" raw-content>
                <i class="bi bi-question-circle-fill"></i>
              </el-tooltip>
            </div>
            <div class="col-md-10 col-9">
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="product_name">
                    <el-input size="large" v-model="formData.product_name" type="text" placeholder="Product Name"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-md-8">
              <label class="me-1">Product Price</label>
              <el-tooltip class="box-item" effect="dark" content="This field represent product price" placement="right" raw-content>
                <i class="bi bi-question-circle-fill"></i>
              </el-tooltip>
            </div>
            <div class="col-md-12 col-9">
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="price">
                    <el-input size="large" v-model="formData.price" type="text" placeholder="Product Price"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="col-md-8">
              <label class="me-1">Product Description</label>
              <el-tooltip class="box-item" effect="dark" content="This field represent product description" placement="right" raw-content>
                <i class="bi bi-question-circle-fill"></i>
              </el-tooltip>
            </div>
            <div class="col-md-10 col-9">
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="description">
                    <el-input size="large" v-model="formData.description" type="text" placeholder="Product Description"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-md-8">
              <label class="me-1">Size Label</label>
              <el-tooltip class="box-item" effect="dark" content="This field represent size label" placement="right" raw-content>
                <i class="bi bi-question-circle-fill"></i>
              </el-tooltip>
            </div>
            <div class="col-md-10 col-9">
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="size_label">
                    <el-input size="large" v-model="formData.size_label" type="text" placeholder="Size Label"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-md-8">
              <label class="me-1">Height</label>
              <el-tooltip class="box-item" effect="dark" content="This field represent height" placement="right" raw-content>
                <i class="bi bi-question-circle-fill"></i>
              </el-tooltip>
            </div>
            <div class="col-md-12 col-9">
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="height">
                    <el-input size="large" v-model="formData.height" type="text" placeholder="Height"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="col-md-8">
              <label class="me-1">Width</label>
              <el-tooltip class="box-item" effect="dark" content="This field represent width" placement="right" raw-content>
                <i class="bi bi-question-circle-fill"></i>
              </el-tooltip>
            </div>
            <div class="col-md-10 col-9">
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="width">
                    <el-input size="large" v-model="formData.width" type="text" placeholder="Width"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-md-8">
              <label class="me-1">Attribute Name</label>
              <el-tooltip class="box-item" effect="dark" content="This field represent attribute name" placement="right" raw-content>
                <i class="bi bi-question-circle-fill"></i>
              </el-tooltip>
            </div>
            <div class="col-md-10 col-9">
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="attribute_name">
                    <el-input size="large" v-model="formData.attribute_name" type="text" placeholder="Attribute Name"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-md-8">
              <label class="me-1">Height</label>
              <el-tooltip class="box-item" effect="dark" content="This field represent height" placement="right" raw-content>
                <i class="bi bi-question-circle-fill"></i>
              </el-tooltip>
            </div>
            <div class="col-md-12 col-9">
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="height">
                    <el-input size="large" v-model="formData.height" type="text" placeholder="Height"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-9">
          <div class="col-md-12 mb-5 mt-12">
            <button type="button" class=" btn btn-primary me-2" :disabled="loading" @click="submitForm(formRef, 'save')">{{generalConstants.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </button>
            <button type="button" class=" btn btn-primary me-2" :disabled="loadingback" @click="submitForm(formRef, 'save_back')">{{generalConstants.SAVEANDBACK}}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
            <button type="button" class=" btn btn-secondary me-2" @click.prevent="$router.push({ name: 'trading-partners' })">{{generalConstants.CANCEL}}</button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, onUpdated } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useRouter,useRoute } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import useEventBus from '../../composable/useEventBus'
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";

export default {
  name: "product-listing",
  components: {
    Datatable,
  },
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const router = useRouter();
    const route = useRoute();
    const loading = ref(false);
    const loadingback = ref(false);
    const disabled = ref(false);
    const formRef = ref(null);
    const generalConstants = globalConstant.general;
    const tradingPartnerConstants = globalConstant.tradingPartner;
    const formData = ref({
      partner_sku_code: "",
      partner_sku_description: "",
      sku_code: "",
      sku_description: "",
      product_name: "",
      price: "",
      description: "",
      size_label: "",
      height: "",
      width: "",
      attribute_name: "",
    });

    //Get click event of toolbar buttons
    onEvent("actionName", (actionName) => {
      if (actionName == "save") {
        submitForm(formRef, "save", true);
      } else if (actionName == "save_back") {
        submitForm(formRef, "save_back", true);
      } else if (actionName == "cancel") {
        router.push({ name: "trading-partners" });
      }
    });
    //Get product mapping data
    const getProductMappingData = async () => {
      await ApiService.post("product-import-history/get-product-mapping-data", { tradingPartnerId: route.params.id })
        .then(({ data }) => {
          formData.value.partner_sku_code = data.data.product_mapping.partner_sku_code.replace(/_/g, ' ');
          formData.value.partner_sku_description = data.data.product_mapping.partner_sku_description.replace(/_/g, ' ');
          formData.value.sku_code = data.data.product_mapping.sku_code.replace(/_/g, ' ');
          formData.value.sku_description = data.data.product_mapping.sku_description.replace(/_/g, ' ');
          formData.value.product_name = data.data.product_mapping.product_name.replace(/_/g, ' ');
          formData.value.price = data.data.product_mapping.price.replace(/_/g, ' ');
          formData.value.description = data.data.product_mapping.description.replace(/_/g, ' ');
          formData.value.size_label = data.data.product_mapping.size_label.replace(/_/g, ' ');
          formData.value.height = data.data.product_mapping.height.replace(/_/g, ' ');
          formData.value.width = data.data.product_mapping.width.replace(/_/g, ' ');
          formData.value.attribute_name = data.data.product_mapping.attribute_name.replace(/_/g, ' ');
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    }

    //Submit form for product mapping data
    const submitForm = async (formEl, btnName, isToolbarButtonsSubmitAction=false) => {
      formEl = (isToolbarButtonsSubmitAction) ? formEl.value : formEl;
      (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":true,"action":btnName}) : (btnName == "save")?(loading.value = true) : (loadingback.value = true);
      ApiService.post('product-import-history/product-mapping', { 
        tradingPartnerId: route.params.id,
        mappingData: formData.value,
        })
      .then(({ data }) => {
        notificationFire(data.message, "success");
        if(btnName == "save_back"){
          router.push({ name: "trading-partners" });
        }
        (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);
      })
      .catch((error) => {
        (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);
        let response = error.response;
        if (response != "") {
          let message = response.data.message;
          notificationFire(message, "error");
        }
      });
    };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name" :generalConstants.SAVE, 'action': 'save','class': 'btn-primary me-3','isLoader': false }, {"name": generalConstants.SAVEANDBACK , 'action': 'save_back','class': 'btn-primary me-3','isLoader':false }, {"name": generalConstants.CANCEL , 'action': 'cancel','class': 'btn-secondary','isLoader':false }]);
    }
    
    //Set breadcrumbs and get the products
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": formatText('trading-partners'), "path": "trading-partners"}, { name: formatText(route.name), active: true }]);
      generateHeaderToolbarButtons();
      await getProductMappingData();
    })
    onUpdated(async () => {
      generateHeaderToolbarButtons();
    })

    return {
      loading,
      loadingback,
      disabled,
      formRef,
      formData,
      submitForm,
      generalConstants,
      tradingPartnerConstants
    };
  },
};
</script>
